import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Text from "../components/text/text"

const ParentsPage = () => (
	<Layout>
		<div className="heading-wrap">
			<div className="heading">
				<center>
					<h1>Батькам</h1>
				</center>
			</div>
		</div>

        <Text>
            <h2>М’яке звикання до садочку</h2>

            <p>
                Готуємось вдома<br />
                Психологічний настрій батьків є головним орієнтиром для самопочуття дитини.<br />
                Внутрішні переживання батьків створюють «атмосферу садочку», яка поширюється далеко за його фізичні межі. Якщо батькам тривожно і страшно — радше за все так буде і для дитини. Якщо для батьків похід у садочок — це щось приємно хвилююче і про очікування гарних змін — тоді і простір садочку буде сприйматись дитиною як більш дружній і приємний.
            </p>

            <h2>Кредит довіри до садочку</h2>

            <p>
                Довіра і впевненість батьків у емоційному та фізичному благополуччі дитини – це кращий початок садочкового життя.<br />
                Батькам важливо розповідати дитині, що садочок — це місце, яке спеціально створено для дитини та її потреб. Що вихователі в садочку — це помічники для дитини, які дбають про неї, і дбають про її фізичне самопочуття, хороший настрій, підтримують дитину.<br />
                Разом з тим, там є певні правила, щоб дитині в садочку було безпечніше, зручніше і корисніше.
            </p>
            <p>
                Підтримуючі бажання дитини йти в садочок питання:<br />
                З ким ти хотів би погратись сьогодні?<br />
                Яка іграшка на тебе чекає сьогодні?<br />
                Що б тобі хотілось зробити на занятті?
            </p>
            <p>
                Розповідайте дитині більше про садочки і їх відвідувачів в цілому. Про те, що хорошого було у вашому садочку в дитинстві. Про друзів вашої дитини, які відвідують садочок.<br />
                Уникайте критичних розмов у сім’ї про садок у присутності дитини, оскільки вони можуть сформувати для неї відповідне ставлення до відвідування садка.
            </p>
            <p>
                Такі слова: «тебе не будуть ображати», можуть бути для дитини не підтримуючими, а тривожними «а в садочку мова йде про образи? І мені треба готуватись?!»<br />
                Натомість можна сказати: «Ось твоя вихователька пані Наталя, якщо будуть якісь труднощі, кажи їй!»<br />
            </p>
            <p>
                Чесно орієнтуйте дитину щодо часу, коли плануєте її забрати, прив’язуючи його до режимних моментів садочку (я тебе заберу, коли: ти повернешся з прогулянки, коли ти повечеряєш, коли завершаться танці). Краще сказати дитині, що ви заберете її після вечері, і забрати раніше, аніж навпаки. Якщо батьки кажуть, що заберуть дитину в певний час і не забирають, то дитина чекає, і займатись чимось іншим їй складно. Окрім того, така ситуація атакує довіру дитини до батьків.<br />
                Ви можете давати дитині улюблену іграшку з собою (у якості перехідного об’єкту). Так вона зможе мати «часточку батьків» завжди з собою.<br />
                Підтримуйте самостійність дитини вдома.
            </p>
            <p>
                Прощання з дитиною зранку.<br />
                Прощайтесь з дитиною лаконічно і з легким серцем.<br />
                Основний об’єм турботи, любові, обіймів та уваги надавайте дитині до садочка або після. Спроба надолужити чи вкласти весь об’єм своєї любові зранку під час прощання складно переживається дитиною і самими батьками. Дитині потім важче включитись у групову діяльність.<br />
                Обов’язково прощайтесь з дитиною, виходячи з садочку, навіть якщо вона засмучена чи відволіклась: «Доця, бувай, я тебе люблю, заберу після вечері».<br />
                Якщо батьки «тікають» без прощання — це підвищуватиме рівень тривоги дитини на наступні дні.<br />
                В жодному разі просимо не говорити дитині, що ви зараз повернетесь, якщо ви не маєте в планах так і зробити. Не робіть так. Також і тому, що дитина буде чекати, і не зможе отримати задоволення від садочкової діяльності. І такі ситуації також атакують довіру дитини до батьків.<br />
                Дитині можливо знадобиться час, щоб прожити сум після прощання з батьками, і ми будемо поруч і підтримаємо її.
            </p>

            <h2>Під час перебування дитини у садочку</h2>
            
            <p>
                Зберігайте спокій.<br />
                З вашою дитиною знаходяться люди, які дбають про неї, захищають та оберігають її.<br />
                А ви з легким серцем маєте час для себе чи своїх справ.<br />
            </p>

            <h2>Після садочку</h2>

            <p>
                Якщо є потреба, запитуйте у педагогинь і дитини як минув її день.<br />
                Поцікавтесь у дитини, як її справи, з ким вона бавилась у садочку, як настрій, що було на обід, тощо.<br />
                По можливості, варто знаходити час вислухати, що непокоїть вашу дитину, які у неї труднощі і радості, чого вона досягла і що хотілось би осягнути.<br />
            </p>
            <p>
                Іноді непросто відчувати себе компетентними батьками, коли в житті додається стільки змін. Це є природньо і нормально.<br />
                Ми знаємо, що діти часом плачуть. І не тому, що в садочку недобре, а тому що люблять дуже
            </p>
        </Text>
	</Layout>
)

export const Head = () => <Seo title="Батькам" />

export default ParentsPage
